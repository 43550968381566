import axiosObj, {
  axiosBroadcast,
  axiosMock,
  axiosUploader,
  rawAxios,
  walletAxios,
  axiosProd,
  affAxios,
} from "core/config/axios";
import jsonpAdapter from "axios-jsonp";
import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";
import { logger } from "library/core/utility";
import { UserRole } from "library/core/types";
import config from "core/config";

type REQUEST_METHODS =
  | "get"
  | "GET"
  | "delete"
  | "DELETE"
  | "head"
  | "HEAD"
  | "options"
  | "OPTIONS"
  | "post"
  | "POST"
  | "put"
  | "PUT"
  | "patch"
  | "PATCH"
  | undefined;

interface EndPoint {
  get: Function;
  post: Function;
  delete: Function;
  patch: Function;
  put: Function;
  jsonp: Function;
  options: Function;
  raw: AxiosInstance;
}

type RequestParams = {
  pathParams: string[] | string;
  queryParams: { [name: string]: string } | string;
};

type RequestData = { [name: string]: any } | string | undefined;

export class ApiStore {
  /*
   * Common Endpoints Endpoints
   */
  public fail: EndPoint = this.createEndpoint("/failEndpoint");
  public getAgreements: EndPoint = this.createEndpoint(
    "/accounts/signup-agreement/"
  );
  public setPassword: EndPoint = this.createEndpoint("/accounts/set-password/");
  public impersonate: EndPoint = this.createEndpoint("/jwt/impersonate/");
  public countries: EndPoint = this.createEndpoint("/models/countries/"); //this says models but also applicable to studios
  /*
   * Models Endpoints
   */
  public modelsBase: EndPoint = this.createEndpoint("/models/");
  public modelMeProfileCompletion: EndPoint = this.createEndpoint("/fail/");
  public walletModelPeriods: EndPoint = this.createEndpoint(
    "/wallet/model/periods/",
    walletAxios
  );
  public walletModelMembersTransactions30days: EndPoint = this.createEndpoint(
    "/wallet/model/members-transactions-30-days",
    walletAxios
  );
  public walletModelMembersTransactionsPastYear: EndPoint = this.createEndpoint(
    "/wallet/model/members-transactions-year",
    walletAxios
  );
  public walletModelMembersTransactions: EndPoint = this.createEndpoint(
    "/wallet/model/members-transactions-time-period",
    walletAxios
  );
  public walletModelCurrentEarnings: EndPoint = this.createEndpoint(
    "/wallet/model/earnings/",
    walletAxios
  );
  public walletModelCurrentPeriodEarning: EndPoint = this.createEndpoint(
    "/wallet/model/current-period-earnings",
    walletAxios
  );
  public walletModelGiftsRecievedLast30Days: EndPoint = this.createEndpoint(
    "/wallet/model/virtual-gifts-earnings",
    walletAxios
  );
  public walletModelCurrent: EndPoint = this.createEndpoint(
    "/wallet/model/current/",
    walletAxios
  );
  public walletModelPayoutMethods: EndPoint = this.createEndpoint(
    "/models/payout-methods/"
  );
  public pricing: EndPoint = this.createEndpoint("/models/me/pricing/");
  public pricingOptions: EndPoint = this.createEndpoint(
    "/models/me/pricing/options"
  );
  public mediaDefaultPricing: EndPoint = this.createEndpoint(
    "/models/me/pricing/"
  );
  public albums: EndPoint = this.createEndpoint("/models/me/albums/");
  public images: EndPoint = this.createEndpoint("/models/me/images/");
  public videos: EndPoint = this.createEndpoint("/models/me/videos/");
  public gifts: EndPoint = this.createEndpoint("/models/me/gifts/", axiosMock);
  public awards: EndPoint = this.createEndpoint(
    "/models/me/awards/",
    axiosMock
  );

  public modelLogin: EndPoint = this.createEndpoint("/models/jwt/token/");
  public modelLogout: EndPoint = this.createEndpoint("/models/jwt/logout/");
  public modelMe: EndPoint = this.createEndpoint("/models/me/");
  public modelTokenRefresh: EndPoint = this.createEndpoint(
    "/models/jwt/refresh/",
    rawAxios
  );
  public contests: EndPoint = this.createEndpoint("/models/me/contests/");
  public messages: EndPoint = this.createEndpoint("/notifications/");
  public contractDocuments: EndPoint = this.createEndpoint(
    "/models/contract-documents/",
    axiosMock
  );
  public kinks: EndPoint = this.createEndpoint("/models/kinks/");
  public specialities: EndPoint = this.createEndpoint("/models/specialities/");
  public attributes: EndPoint = this.createEndpoint("/models/attributes/");
  public referrals: EndPoint = this.createEndpoint(
    "/models/me/referred-users/"
  );
  public savedMessages: EndPoint = this.createEndpoint(
    "/models/me/saved-messages/"
  );
  public wheelOfFunRewards: EndPoint = this.createEndpoint(
    "/models/me/pricing/"
  );
  public modelSignDocumentUpload: EndPoint = this.createEndpoint(
    "/models/me/sign-document-upload/"
  );
  public modelStarRatings: EndPoint = this.createEndpoint(
    "/models/me/star_ratings/"
  );
  public modelsForgotPassword: EndPoint = this.createEndpoint(
    "/models/forgot-password/"
  );
  public modelsForgotUsername: EndPoint = this.createEndpoint(
    "/models/forgot-username/"
  );
  public modelsUpdatePassword: EndPoint = this.createEndpoint(
    "/models/update-password/"
  );
  public modelsSetPassword: EndPoint = this.createEndpoint(
    "/models/set-password/"
  );
  public modelsTopModels: EndPoint = this.createEndpoint("/models/top-models");
  public modelsWelcomeTutorial: EndPoint = this.createEndpoint(
    "/models/me/show-welcome-tutorial/"
  );
  public modelMeSetProfileImage: EndPoint = this.createEndpoint(
    "/models/me/set-profile-image/"
  );
  public modelVerifyAge: EndPoint = this.createEndpoint("/models/verify-age/");
  public suspendAgeVerificationMember: EndPoint = this.createEndpoint(
    "/models/snooze-member-from-verifier-show/"
  );
  public memberVerificationOptIn: EndPoint = this.createEndpoint(
    "/models/me/set-opt-in-flag/"
  );
  public endTippingSession: EndPoint = this.createEndpoint(
    "/chat/model/end-tipping-session",
    walletAxios
  );
  /*
   * Studio Endpoints
   */
  public studiosBase: EndPoint = this.createEndpoint("/studios/");
  public studioMe: EndPoint = this.createEndpoint("/studios/me/");
  public walletStudioPayoutMethods: EndPoint = this.createEndpoint(
    "/studios/payout-methods/"
  );
  public studioModels: EndPoint = this.createEndpoint(
    "/wallet/studio/models",
    walletAxios
  );
  public studioEarnings: EndPoint = this.createEndpoint(
    "/wallet/studio/earnings",
    walletAxios
  );
  public studioModelEarnings: EndPoint = this.createEndpoint(
    "/wallet/studio/model/",
    walletAxios
  );
  public studioPeriodEarningSummary: EndPoint = this.createEndpoint(
    "/wallet/studio/period-earning-summary",
    walletAxios
  );
  public studioLogin: EndPoint = this.createEndpoint("/studios/jwt/token/");
  public studioLogout: EndPoint = this.createEndpoint("/studios/jwt/logout/");
  public studioTokenRefresh: EndPoint = this.createEndpoint(
    "/studios/jwt/refresh/",
    rawAxios
  );
  public studioSignDocumentUpload: EndPoint = this.createEndpoint(
    "/studios/me/sign-document-upload/"
  );
  public studioModelsTaxFormUpload: EndPoint = this.createEndpoint(
    "/studios/me/tax-form-upload/"
  );
  public studioModelsBroadcastingHoursForPeriod: EndPoint = this.createEndpoint(
    "/studios/me/broadcasting-hours/"
  );
  public studioManagementModels = (studioId): EndPoint =>
    this.createEndpoint(`/studios/${studioId}/models/`);
  public studioManagementModelsAccessRights: EndPoint = this.createEndpoint(
    `/studios/model-permissions/`
  );
  public studioSignDocumentUploadForModel = (studioId, modelId): EndPoint =>
    this.createEndpoint(
      `/studios/${studioId}/models/${modelId}/sign-document-upload/`
    );
  public studiosForgotPassword: EndPoint = this.createEndpoint(
    "/studios/forgot-password/"
  );
  public studiosForgotUsername: EndPoint = this.createEndpoint(
    "/studios/forgot-username/"
  );
  public studiosUpdatePassword: EndPoint = this.createEndpoint(
    "/studios/update-password/"
  );
  public studiosSetPassword: EndPoint = this.createEndpoint(
    "/studios/set-password/"
  );
  /*
   * Member Endpoints
   */

  public members: EndPoint = this.createEndpoint("/members/");

  /*
   * Broadcast Endpoints
   */
  public curtainDown: EndPoint = this.createEndpoint(
    "/broadcasting/api/goals/curtain-down/",
    axiosBroadcast
  );
  public broadcastStats: EndPoint = this.createEndpoint(
    "/broadcasting/api/stats/live/",
    axiosBroadcast
  );
  public broadcastShows: EndPoint = this.createEndpoint(
    "/broadcasting/api/shows/",
    axiosBroadcast
  );
  public broadcastShowSessions: EndPoint = this.createEndpoint(
    "/broadcasting/api/show-sessions/",
    axiosBroadcast
  );
  public broadcastViewSession: EndPoint = this.createEndpoint(
    "broadcasting/api/view-sessions",
    axiosBroadcast
  );
  public broadcastSessions: EndPoint = this.createEndpoint(
    "/broadcasting/api/broadcast-sessions/",
    axiosBroadcast
  );
  public broadcastPublishers: EndPoint = this.createEndpoint(
    "/broadcasting/api/urls/publishers/",
    axiosBroadcast
  );
  public broadcastAnonymous: EndPoint = this.createEndpoint(
    "/broadcasting/api/urls/anonymous/",
    axiosBroadcast
  );
  public broadcastAdmins: EndPoint = this.createEndpoint(
    "/broadcasting/api/urls/admins/",
    axiosBroadcast
  );
  public broadcastViewers: EndPoint = this.createEndpoint(
    "/broadcasting/api/urls/viewers/",
    axiosBroadcast
  );
  public broadcastRecorders: EndPoint = this.createEndpoint(
    "/broadcasting/api/recorders/",
    axiosBroadcast
  );
  public broadcastConstants: EndPoint = this.createEndpoint(
    "/broadcasting/constants/",
    axiosBroadcast
  );
  public broadcastPrivateRequests: EndPoint = this.createEndpoint(
    "/broadcasting/private-requests/",
    axiosBroadcast
  );
  public broadcastChat: EndPoint = this.createEndpoint(
    "/broadcasting/chat/",
    axiosBroadcast
  );

  public broadcastGuestModelConfirmation: EndPoint = this.createEndpoint(
    "/broadcasting/guest-model-confirmation/",
    axiosBroadcast
  );

  public broadcastViewSessions: EndPoint = this.createEndpoint(
    "/broadcasting/api/view-sessions/",
    axiosBroadcast
  );

  /*
   * Chat Endpoints
   */
  public chatPublicMembers: EndPoint = this.createEndpoint(
    "/chat/model/public/members",
    walletAxios
  );
  public chatPrivateMembers: EndPoint = this.createEndpoint(
    "/chat/model/private/members",
    walletAxios
  );
  public chatTopAdmirers: EndPoint = this.createEndpoint(
    "/wallet/model/top-admirers",
    walletAxios
  );
  public chatLanguage: EndPoint = this.createEndpoint(
    "/chat/language",
    walletAxios
  );

  public activeTopAdmirers = (id: number, timestamp: number): EndPoint =>
    this.createEndpoint(
      `/api/legacy_camschat?type=top_admirers&stream_id=${id}&timestamp=${timestamp}`,
      axiosProd
    );

  /*
   * Uploader Endpoints
   */
  public attachmentUploader: EndPoint = this.createEndpoint(
    "/uploader",
    axiosUploader
  );

  private interceptorIds: number[] = [];

  public getBaseEndpoint(profileType: UserRole | null) {
    return profileType === "studio" ? this.studiosBase : this.modelsBase;
  }

  public getMeEndpoint(profileType: UserRole | null) {
    return profileType === "studio" ? this.studioMe : this.modelMe;
  }

  public getLoginEndpoint(profileType: UserRole | null) {
    return profileType === "studio" ? this.studioLogin : this.modelLogin;
  }

  public getUpdatePasswordEndpoint(profileType: UserRole | null) {
    return profileType === "studio"
      ? this.studiosUpdatePassword
      : this.modelsUpdatePassword;
  }

  public getSetPasswordEndpoint(_profileType: UserRole | null) {
    return this.setPassword;
    //return profileType === "studio" ? this.studiosSetPassword : this.modelsSetPassword;
  }

  public getForgotPasswordEndpoint(profileType: UserRole | null) {
    return profileType === "studio"
      ? this.studiosForgotPassword
      : this.modelsForgotPassword;
  }

  public getForgotUsernameEndpoint(profileType: UserRole | null) {
    return profileType === "studio"
      ? this.studiosForgotUsername
      : this.modelsForgotUsername;
  }

  public getLogoutEndpoint(profileType: UserRole | null) {
    return profileType === "studio" ? this.studioLogout : this.modelLogout;
  }

  public getTokenRefreshEndpoint(profileType: UserRole | null) {
    return profileType === "studio"
      ? this.studioTokenRefresh
      : this.modelTokenRefresh;
  }

  public getSignDocumentUploadEndpoint(profileType: UserRole | null) {
    return profileType === "studio"
      ? this.studioSignDocumentUpload
      : this.modelSignDocumentUpload;
  }

  public getSignDocumentUploadEndpointForModel(studioId, modelId) {
    return this.studioSignDocumentUploadForModel(studioId, modelId);
  }

  public getStudioManagementModelsEndpoint(studioId) {
    return this.studioManagementModels(studioId);
  }

  public getGetPayoutMethodsEndpoint(profileType: UserRole | null) {
    return profileType === "studio"
      ? this.walletStudioPayoutMethods
      : this.walletModelPayoutMethods;
  }

  public token = "";

  constructor() {
    (window as any).apiUtil = this;
  }

  attachAuthorizationHeader = (
    accessToken: string,
    axiosInstance: AxiosInstance
  ) => {
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    };
  };

  removeAuthorizationHeader = (axiosInstance: AxiosInstance) => {
    delete axiosInstance.defaults.headers.common["Authorization"];
  };

  public setTokenHeader(accessToken) {
    this.token = accessToken;
    this.axiosInstances.forEach(ax =>
      this.attachAuthorizationHeader(accessToken, ax)
    );
  }

  public deleteTokenHeader() {
    this.axiosInstances.forEach(ax => this.removeAuthorizationHeader(ax));
  }

  public get axiosInstances() {
    return [axiosObj, walletAxios, axiosBroadcast, axiosUploader, affAxios];
  }

  public setInterceptorResponseFunctions(onSuccess, onError) {
    this.axiosInstances.forEach(ax =>
      this.interceptorIds.push(ax.interceptors.response.use(onSuccess, onError))
    );
  }

  public removeInterceptorResponseFunctions() {
    this.axiosInstances.forEach(ax => {
      this.interceptorIds.forEach(interceptorId =>
        ax.interceptors.response.eject(interceptorId)
      );
    });
  }

  public createEndpoint(
    url: string,
    axiosInstance: AxiosInstance = axiosObj
  ): EndPoint {
    if (config.flightModeOn) {
      const fakeFn = () => {};
      const fakeEndpointFn = (
        _data?: RequestData | RequestParams,
        _urlExtension?,
        _headers?
      ) => {
        return fakeFn;
      };
      return {
        get: fakeEndpointFn,
        post: fakeEndpointFn,
        put: fakeEndpointFn,
        patch: fakeEndpointFn,
        delete: fakeEndpointFn,
        options: fakeEndpointFn,
        jsonp: fakeEndpointFn,
        raw: axiosObj,
      };
    }
    return {
      get: (data?: RequestData | RequestParams, urlExtension?, headers?) => {
        return this.makeRequest(
          url + (urlExtension ? urlExtension : ""),
          "GET",
          data,
          axiosInstance,
          headers
        );
      },
      post: (
        data?: RequestData | RequestParams,
        urlExtension?,
        headers?,
        config?: AxiosRequestConfig
      ) => {
        return this.makeRequest(
          url + (urlExtension ? urlExtension : ""),
          "POST",
          data,
          axiosInstance,
          headers,
          config
        );
      },
      delete: (data?: RequestData | RequestParams, urlExtension?, headers?) => {
        return this.makeRequest(
          url + (urlExtension ? urlExtension : ""),
          "DELETE",
          data,
          axiosInstance,
          headers
        );
      },
      patch: (data?: RequestData | RequestParams, urlExtension?, headers?) => {
        return this.makeRequest(
          url + (urlExtension ? urlExtension : ""),
          "PATCH",
          data,
          axiosInstance,
          headers
        );
      },
      put: (data?: RequestData | RequestParams, urlExtension?, headers?) => {
        return this.makeRequest(
          url + (urlExtension ? urlExtension : ""),
          "PUT",
          data,
          axiosInstance,
          headers
        );
      },
      options: (
        data?: RequestData | RequestParams,
        urlExtension?,
        headers?
      ) => {
        return this.makeRequest(
          url + (urlExtension ? urlExtension : ""),
          "OPTIONS",
          data,
          axiosInstance,
          headers
        );
      },
      jsonp: (data?: RequestData | RequestParams, urlExtension?, headers?) => {
        return this.makeRequest(
          url + (urlExtension ? urlExtension : ""),
          "JSONP",
          data,
          axiosInstance,
          headers
        );
      },
      raw: axiosInstance,
    };
  }

  private makeRequest(
    url: string,
    method: REQUEST_METHODS | "JSONP",
    data: RequestData | RequestParams = {},
    axiosInstance = axiosObj,
    headers?,
    config?
  ): AxiosPromise {
    // let cancel;
    const hasUrlExtension = typeof data === "string";
    const hasUrlParams =
      (!hasUrlExtension && !!(data as RequestParams).pathParams) ||
      !!(data as RequestParams).queryParams;
    if (hasUrlParams) {
      url = this.formatUrl(
        url,
        (data as RequestParams).pathParams,
        (data as RequestParams).queryParams
      );
    }
    if (hasUrlExtension) {
      url = url + data;
    }
    const axiosRequestConfig: AxiosRequestConfig = {
      url,
      data,
      ...(method === "JSONP" ? { adapter: jsonpAdapter } : { method }),
      headers,
      ...config,
      // cancelToken: new axios.CancelToken(function executor(c) {cancel = c;})
    };

    if (url.includes("/api/stats/live")) {
      axiosRequestConfig.validateStatus = () => true;
    }

    return axiosInstance(axiosRequestConfig);
    // (promise as any).cancel = cancel
    // const promiseResp : AxiosPromise & {cancel} = promise as AxiosPromise & {cancel}
    // return promiseResp;
  }

  public formatUrlExtension(pathParams, queryParams): string {
    return (
      this.formatPathParams(pathParams) + this.formatQueryParams(queryParams)
    );
  }

  public formatUrl(url: string, pathParams, queryParams): string {
    return url + this.formatUrlExtension(pathParams, queryParams);
  }

  public formatQueryParams(queryParams: {} | string = ""): string {
    if (typeof queryParams === "string") {
      return queryParams;
    } else {
      const paramKeys = Object.keys(queryParams);
      return paramKeys.length
        ? paramKeys
            .filter(key => queryParams[key] !== undefined)
            .map(
              (key, i) =>
                (!!i ? "&" : "") +
                `${encodeURI(key)}=${encodeURI(queryParams[key])}`
            )
            .reduce((url, pair) => url + pair, "?")
        : "";
    }
  }

  public formatPathParams(pathParams: string[] | string): string {
    return Array.isArray(pathParams)
      ? pathParams.join("/") + "/"
      : !!pathParams
      ? pathParams + "/"
      : "";
  }

  public async makeRequestsForAllPages(
    endpointBase,
    endpointExtension
  ): Promise<Object[] | undefined> {
    let records = [];
    let keepGoing = true;
    let page = 1;

    while (keepGoing) {
      try {
        const { data } = await this[endpointBase].get(
          {},
          `${endpointExtension}?page=${page}`
        );

        records = records.concat(data.results);
        page += 1;

        if (!data.next) {
          keepGoing = false;
          return records;
        }
      } catch (error) {
        logger.log("makeRequestsForAllPages error");
        throw new Error(error);
      }
    }
  }
}
export default ApiStore;
